<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="meetingroomData === undefined"
    >
      <h4 class="alert-heading">
        Errore dati Sala Riunioni
      </h4>
      <div class="alert-body">
        Nessuna Sala Riunioni trovata con questo ID. Controlla
        <b-link
          class="alert-link"
          :to="{ name: 'apps-meetingrooms-list' }"
        >
          Lista Sale Riunioni
        </b-link>
        per altre Sale Riunioni.
      </div>
    </b-alert>

    <template v-if="meetingroomData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <meetingrooms-view-meetingroom-info-card :meetingroom-data="meetingroomData" />
        </b-col>
        <b-col cols="12">
          <events-list />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import EventsList from '@/views/apps/events/events-list/EventsList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import MeetingroomsViewMeetingroomInfoCard from './MeetingroomsViewMeetingroomInfoCard.vue'
import meetingroomsStoreModule from '../meetingroomsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    EventsList,
    MeetingroomsViewMeetingroomInfoCard,
  },
  setup() {
    const toast = useToast()
    const meetingroomData = ref(null)

    const MEETINGROOMS_APP_STORE_MODULE_NAME = 'app-meetingrooms'

    // Register module
    if (!store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(MEETINGROOMS_APP_STORE_MODULE_NAME, meetingroomsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(MEETINGROOMS_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-meetingrooms/fetchMeetingroom', { id: router.currentRoute.params.id })
      .then(response => {
        meetingroomData.value = response.data
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      meetingroomData,
    }
  },
}
</script>

<style></style>
